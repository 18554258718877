/*eslint no-unreachable: "off"*/

export const GET_PROJECTS = "GET_PROJECTS";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
export const FILTER_PROJECTS = "FILTER_PROJECTS";
export const DELETE_PROJECT = "DELETE_PROJECT";

export const getProjects = () => {
  try {
    return async (dispatch) => {
      // Fetch to get all projects from server; Once the projects are fetched the array is randomized to make the experience of visiting the website unique every time.
      const response = await fetch(`https://increasing-dorri-ofineo-936bb2bf.koyeb.app/projects`);
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const resData = await response.json();

      dispatch({
        type: GET_PROJECTS,
        projects: resData.projects,
      });
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const filterProjects = (filteredProjects) => {
  return {
    type: FILTER_PROJECTS,
    filteredProjects: filteredProjects,
  };
};
export const addProject = (token, project) => {
  try {
    return async (dispatch) => {
      // POST a new project to the DB
      const response = await fetch(
        `https://increasing-dorri-ofineo-936bb2bf.koyeb.app/projects/add`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "Application/json",
          },
          body: JSON.stringify(project),
        }
      );
      if (!response.ok) {
        const resData = await response.json();
        console.log(resData);
        throw new Error("Something went wrong");
      }
      const resData = await response.json();
      await dispatch({
        type: ADD_PROJECT,
        projects: resData,
      });
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteProject = (token, projectId) => {
  try {
    return (dispatch) => {
      fetch(`https://increasing-dorri-ofineo-936bb2bf.koyeb.app/projects/${projectId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Something went wrong");
          }
          return response.json();
        })
        .then((resData) =>
          dispatch({
            type: DELETE_PROJECT,
            id: resData,
          })
        );
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateProject = (token, project) => {
  try {
    return (dispatch) => {
      fetch(`https://increasing-dorri-ofineo-936bb2bf.koyeb.app/projects/${project._id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "Application/json",
        },
        body: JSON.stringify(project),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Something went wrong");
          }
          return response.json();
        })
        .then((resData) => {
          dispatch({
            type: UPDATE_PROJECT,
            project: resData
            
          });
        });
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};
