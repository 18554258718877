import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as projectActions from "../../store/actions/projects";
import "./Search.css";

const Search = (props) => {
  const projects = useSelector((state) => state.projects.projects);
  const dispatch = useDispatch();

  const filterProjects = (value) => {
    const regex = RegExp(value, "i");

    const selected = document.getElementsByClassName("selected");
    while (selected.length > 0) {
      selected[0].classList.toggle("selected");
    }
    document.querySelector(`li.${value}`).classList.toggle("selected");
    document.querySelector(`li.${value}+span`).classList.toggle("selected");

    if (value !== "all") {
      let searchResult = projects.filter((project) =>
        regex.test(project.service)
      );
      dispatch(projectActions.filterProjects(searchResult));
    } else {
      dispatch(projectActions.filterProjects(projects));
    }
  };

  return (
    <aside>
      <ul className="app-list">
        <li className="all selected" onClick={() => filterProjects("all")}>
          All Projects
      </li>
        <span className="selected">
          <i className="fa fa-bullseye" aria-hidden="true"></i>
        </span>
        <li className="front" onClick={() => filterProjects("front")}>
          Front-End
      </li>
        <span>
          <i className="fa fa-bullseye" aria-hidden="true"></i>
        </span>
        <li className="backend" onClick={() => filterProjects("backend")}>
          Back-End
      </li>
        <span>
          <i className="fa fa-bullseye" aria-hidden="true"></i>
        </span>
        <li className="js" onClick={() => filterProjects("js")}>
          Other
      </li>
        <span>
          <i className="fa fa-bullseye" aria-hidden="true"></i>
        </span>
      </ul>
    </aside>
  );
};

export default Search;
