// src/components/NavBar.js

import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Navbar, Nav, Button, Form, Image } from 'react-bootstrap';
import './NavBar.css'

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <Navbar bg="light" variant="dark" expand="md">
      <a href="/">
        <Image className="nav-image" src="../Ofineo.png" alt="small icon waving" height="40" width="40" roundedCircle />
      </a>
      <Nav className="mr-auto">
        <span className="social">
          <a target="blank" rel="noopener noreferrer" href="https://www.facebook.com/jordi.melendez">
            <i className="fa fa-facebook-official fa-3x faa-tada animated-hover" aria-hidden="true"></i>
          </a>
          <a target="blank" rel="noopener noreferrer" href="https://www.instagram.com/ofineo/">
            <i className="fa fa-instagram fa-3x faa-tada animated-hover" aria-hidden="true"></i>
          </a>
          <a target="blank" rel="noopener noreferrer" href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile">
            <i className="fa fa-linkedin-square fa-3x faa-tada animated-hover" aria-hidden="true"></i>
          </a>
          <a href="mailto:ofineo@gmail.com?Subject=Web%20CV%20Contact" target="_top" >
            <i className="fa fa-envelope-o fa-3x faa-tada animated-hover" aria-hidden="true"></i>
          </a>
          <a target="blank" rel="noopener noreferrer" href="https://github.com/Ofineo">
            <i className="fa fa-github fa-3x faa-tada animated-hover" aria-hidden="true"></i>
          </a>
        </span>
        {isAuthenticated && (
          <Nav>
            <Nav.Link href="skill-form">Add new skill</Nav.Link>
            <Nav.Link href="project-form">Add new project</Nav.Link>
          </Nav>
        )}
      </Nav>
      <Form inline>
        {!isAuthenticated && (
          <Button onClick={() => loginWithRedirect({})}>Log in</Button>
        )}
        {isAuthenticated && <Button onClick={() => logout()}>Log out</Button>}
        {isAuthenticated && (
          <span>
            <Nav.Link href="/profile">Profile</Nav.Link>
          </span>
        )}
      </Form>
    </Navbar>
  );
};

export default NavBar;