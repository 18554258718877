import React,{useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './MyProfile.css';

const MyProfile = () => {

    useEffect(()=>{
        document.title= 'About me'
    })

    return (
        <Container className="myprofile-container" as="main">
            <Row>
                <Col xs="10" md="12" lg="10">
                    <h1>
                        Hi I'm Jordi,
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col xs="10" md="12" lg="10">
                    <h2>
                        I'm a Full-Stack web developer based in Barcelona.
                        </h2>
                </Col>
            </Row>
            <Row as="section">
                <Col xs="12" sm="10" md="4" lg="3" as="article" className="profile-description">
                    <p>
                    I have always been passionate about programming. I started developing applications in C#, but what I enjoyed the most was the XAML part of it. That's how I decided to go into front-end web application development. I love building attractive and responsive applications and continuously improving them to make them outstanding.
                    </p>
                    <p>
                    I always strive to get the full picture. I wasn't satisfied with only working with APIs; I wanted to understand what was behind them. That's how I began learning back-end development. Now, I'm a full-stack developer currently working with React on the front end and Node.js on the back end. I enjoy challenging myself, learning new programming paradigms, and exploring new languages.
                   </p>
                </Col >
                <Col xs="12" sm="10" md="5" as="article" className="profile-main">

                    <p>
                        I studied electronics, microprocessor (PLD), and PLC programming. I worked in controls for years, but it was the programming part—communicating on the network, ensuring the packets were correct, and the logic behind it—that I truly enjoyed. This experience taught me to think logically and to find creative solutions, often developing personalized solutions to problems. I always kept my programs tidy, with relevant comments to make maintenance easier later. I started with Turbo Pascal, had a brief stint with Visual Basic, and then moved on to C#. C# was interesting, but the web kept calling. I eventually listened and got hooked on JavaScript, AngularJS, and now ReactJS. I've always enjoyed working in a team, learning new skills, and exploring new ways to work. I would describe myself as a lifelong learner, never satisfied with just basic knowledge but striving for a complete understanding.
                    </p>
                    <p>
                        I'm not only a programmer though—I love experiencing new cultures through travel and staying healthy and fit. I lived in the UK since 2008, and in 2024, I moved to Barcelona. I loved the rich culture and personality of the British people, and the UK holds a special place in my heart.
                    </p>
                    <p>
                        P.S. I love Mexico too.
                    </p>
                </Col>
               
            </Row>
            <Row as="section" className="portfolio-link">
                 <Col xs="12">
                <a target="_blank" rel="noopener noreferrer" href="https://interactive-resume-2753d.web.app/">
                 Previous version of my portfolio build with AngularJS
                </a>
                </Col>
            </Row>


        </Container>
    )
}

export default MyProfile; 